import { useEffect, useRef } from 'react';
import { isWestHamTicketsDomain } from '../utils/utils';

const P1_TRAVEL_COOKIEBOT_ID = '8ec08539-9882-444b-8f50-4d685b419bc1';
const WEST_HAM_TICKETS_COOKIEBOT_ID = 'b8e9e48a-2ca9-4dc2-963d-6e0ba6a7f0ad';

export const useCookieBot = ({
    language,
    shouldInitialize,
}: {
    language: string;
    shouldInitialize: boolean;
}) => {
    const isCookiebotAdded = useRef(false);

    useEffect(() => {
        if (isCookiebotAdded.current || !shouldInitialize) return;

        const COOKIE_BOT_ID = isWestHamTicketsDomain
            ? WEST_HAM_TICKETS_COOKIEBOT_ID
            : P1_TRAVEL_COOKIEBOT_ID;

        if (!COOKIE_BOT_ID) return;

        const script = document.createElement('script');
        script.setAttribute('id', 'Cookiebot');
        script.setAttribute('src', 'https://consent.cookiebot.eu/uc.js');
        script.setAttribute('data-cbid', COOKIE_BOT_ID);
        script.setAttribute('type', 'text/javascript');
        script.setAttribute('data-culture', language);
        script.setAttribute('data-consentmode-defaults', 'disabled');
        script.setAttribute('async', 'true');

        document.head.appendChild(script);

        isCookiebotAdded.current = true;

        return () => {
            document.head.removeChild(script);
        };
    }, [language, shouldInitialize]);

    return null;
};
