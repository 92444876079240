import { Grid } from '@mui/material';
import sumBy from 'lodash/sumBy';
import moment, { Moment } from 'moment';
import { Dispatch, SetStateAction, useCallback, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { colors } from 'src/app/constants/theme';
import { RoomLayoutSelectorForm } from 'src/app/forms/RoomLayoutSelector/RoomLayoutSelectorForm';
import { useAppLocale } from 'src/app/hooks/use-locale';
import { InfoIcon } from 'src/app/icons/InfoIcon';
import RoomLayoutModel from 'src/app/models/RoomLayoutFormModel';
import { selectEventDetail } from 'src/app/store/eventSlice';
import { EventDetail } from 'src/data/models/EventDetail';
import { PenIcon } from 'src/images/icons/PenIcon';
import { FormClickableInput } from 'src/view/components/FormClickableInput/FormClickableInput';
import { FormLabel } from 'src/view/components/FormLabel/FormLabel';
import { VSpacer } from 'src/view/components/Page';
import { Popover } from '..';
import { Body } from '../Body/Body';
import FormSelect from '../FormSelect';
import { OptionsListItem } from '../FormSelect/FormSelect';
import { Modal, ModalBody } from '../Modal/Modal';
import $ from './OrderPreferences.module.scss';

// TODO: Improve the naming convention of the component
export const TravelerWithHotelControls = ({
    incrementalStepOptions,
    hasFixedStepIncrement,
    handleRoomChange,
    setCheckInDate,
    setCheckOutDate,
    checkInDate,
    checkOutDate,
    roomLayout,
}: {
    incrementalStepOptions: number[];
    hasFixedStepIncrement: boolean;
    handleRoomChange: (rooms: RoomLayoutModel[]) => void;
    setCheckInDate: Dispatch<SetStateAction<number>>;
    setCheckOutDate: Dispatch<SetStateAction<number>>;
    checkInDate: number;
    checkOutDate: number;
    roomLayout: RoomLayoutModel[];
}) => {
    const { t } = useTranslation();
    const eventDetail = useSelector(selectEventDetail);

    const { startDateDropdownOptions, endDateDropdownOptions, availableStartDatesCount } =
        useDateOptions(eventDetail);
    const [showSelectRoomsModal, setShowSelectRoomsModal] = useState<boolean>(false);

    const roomLayoutName = useRoomLayoutName(roomLayout);

    /** ------- IMPORTANT --------
     * Be very cautious when changing the handleStartDateChange and handleEndDateChange
     * You might think *handleStartDateChange* should handle *setCheckInDate*
     * and *handleEndDateChange* should handle *setCheckOutDate*
     * THIS IS NOT THE CASE. IT NEEDS INVESTIGATION.
     * TODO: Investigate the cause
     */
    const handleStartDateChange = useCallback(
        (startDate: string | number) => {
            /** DON'T BLINDLY CHANGE TO setCheckInDate */
            setCheckOutDate(availableStartDatesCount - 1 - Number(startDate));
        },
        [availableStartDatesCount]
    );

    const handleEndDateChange = useCallback(
        (endDate: string | number) => {
            /** DON'T BLINDLY CHANGE TO setCheckOutDate */
            setCheckInDate(Number(endDate));
        },
        [availableStartDatesCount]
    );

    return (
        <>
            <Grid item xs={12} md={4} data-cy="ticket-check-in-selector" alignItems="center">
                <FormLabel grey>
                    {t('ticketOutJourney')}{' '}
                    <Popover
                        desktopContent={
                            <Body marginTop={false} marginBottom={false}>
                                {t('ticketFlightPopover')}
                            </Body>
                        }
                        trigger={<InfoIcon stroke={colors.grey} />}
                        buttonClassName={$.travellerWithHotelControlsPopoverButton}
                    />
                </FormLabel>
                <FormSelect
                    options={startDateDropdownOptions}
                    value={availableStartDatesCount - 1 - checkOutDate}
                    onChange={handleStartDateChange}
                    wide
                />
            </Grid>
            <Grid item xs={12} md={4} data-cy="ticket-check-out-selector">
                <FormLabel grey>
                    {t('ticketReturnJourney')}{' '}
                    <Popover
                        desktopContent={
                            <Body marginTop={false} marginBottom={false}>
                                {t('ticketReturnFlightPopover')}
                            </Body>
                        }
                        trigger={<InfoIcon stroke={colors.grey} />}
                        buttonClassName={$.travellerWithHotelControlsPopoverButton}
                    />
                </FormLabel>
                <FormSelect
                    options={endDateDropdownOptions}
                    value={checkInDate}
                    onChange={handleEndDateChange}
                    wide
                />
            </Grid>

            <Grid item xs={12} md={4} data-cy="ticket-room-layout-selector">
                <FormLabel grey>{t('roomLayout')}</FormLabel>
                <FormClickableInput
                    text={roomLayoutName}
                    icon={<PenIcon />}
                    onClick={() => setShowSelectRoomsModal(true)}
                />
            </Grid>

            <VSpacer />

            {eventDetail && (
                <Modal
                    open={showSelectRoomsModal}
                    onClose={() => setShowSelectRoomsModal(false)}
                    title={t('roomLayoutSelectorModalTitle')}
                    subTitle={t('roomLayoutSelectorModalSubtitle')}
                >
                    <ModalBody dataCy="room-layout-modal-content">
                        <RoomLayoutSelectorForm
                            possibleCombinations={incrementalStepOptions}
                            hasFixedIncrement={hasFixedStepIncrement}
                            rooms={roomLayout}
                            eventDate={eventDetail.dateTime}
                            onConfirm={(values) => {
                                handleRoomChange(values.rooms);
                                setShowSelectRoomsModal(false);
                            }}
                        />
                    </ModalBody>
                </Modal>
            )}
        </>
    );
};

function toDateOptions(dates: string[]): OptionsListItem[] {
    const options = dates.map((date, i) => ({
        label: date,
        value: i,
    }));

    return [{ options }];
}

const useDateOptions = (eventDetail: EventDetail | null) => {
    return useMemo(() => {
        if (!eventDetail)
            return {
                startDateDropdownOptions: [],
                endDateDropdownOptions: [],
                availableStartDatesCount: 0,
                endDatesLength: 0,
            };

        const createDateArray = (start: Moment, end: Moment, newDates: string[] = []): string[] => {
            // Determine the starting date:
            // If the provided start date is before today, use today as the starting point.
            const startDate = moment(start).isBefore(moment()) ? moment() : moment(start);

            // Determine the current date to be processed:
            // - If this is the first recursion, start with the `startDate`.
            // - Otherwise, calculate the next date based on the last entry in `newDates`.
            const currentDate =
                newDates.length === 0
                    ? startDate
                    : moment(newDates[newDates.length - 1], 'dddd D MMMM YYYY').add(1, 'day');

            // Base case: If the current date is after the end date, stop recursion and return the results.
            if (currentDate.isAfter(end)) {
                return newDates;
            }

            // Format the current date and add it to the array.
            const format = currentDate.format('dddd D MMMM YYYY');

            // Recursive call:
            // Pass the same `start` and `end`, and update the `newDates` array with the formatted current date.
            return createDateArray(start, end, [...newDates, format]);
        };

        const dateBookableStart = createDateArray(
            eventDetail.dateBookableStart,
            eventDetail.dateMinimumStart
        );

        const dateBookableEnd = createDateArray(
            eventDetail.dateMinimumEnd,
            eventDetail.dateBookableEnd
        );

        return {
            startDateDropdownOptions: toDateOptions(dateBookableStart),
            endDateDropdownOptions: toDateOptions(dateBookableEnd),
            availableStartDatesCount: dateBookableStart.length || 0,
        };
    }, [eventDetail]);
};

const useRoomLayoutName = (roomLayout: RoomLayoutModel[]) => {
    const { t } = useTranslation();
    const locale = useAppLocale();

    return useMemo(() => {
        const nrOfAdults = sumBy(roomLayout, 'adults');
        const nrOfChildren = sumBy(roomLayout, 'children');
        const nrOfRooms = roomLayout.length;

        const formatText = (name: string) => (locale === 'de' ? name : name.toLowerCase());

        const adultsName = t('roomLayoutSelectorAdult', { count: nrOfAdults });
        const childrenName = t('roomLayoutSelectorChild', { count: nrOfChildren });
        const roomName = t('roomLayoutSelector', { count: nrOfRooms });

        // TOOD: Revisit for better approach than this :)
        return `${nrOfAdults} ${formatText(adultsName)}, ${nrOfChildren} ${formatText(
            childrenName
        )}, ${nrOfRooms} ${formatText(roomName)}`;
    }, [roomLayout]);
};
